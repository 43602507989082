<template>
  <order-settings :is-new="true"></order-settings>
</template>

<script>
import orderSettings from "./components/ReservationSettingComponents/index";
export default {
  components: {
    orderSettings
  },
  data() {
    return {};
  }
};
</script>
